@if (inventoryChecks) {
  <div class="popup-content">
    <form [formGroup]="form">
      <div class="row pb-3">
        <div class="col-6">
          <cap-calendar
            appendTo="body"
            formControlName="checked_at"
            [attr.data-test]="'checked_at'"
            [hideOnDateTimeSelect]="true"
            [label]="'dxp.fust.inventory-check.add-inventory-check.checked_at' | translate"
            [placeholder]="'button.select' | translate"
            [showTime]="false"
            [showWeek]="true">
          </cap-calendar>
        </div>
        <div class="col-6">
          <cap-calendar
            appendTo="body"
            formControlName="checked_time"
            [attr.data-test]="'checked_time'"
            [hideOnDateTimeSelect]="true"
            [label]="'dxp.fust.inventory-check.add-inventory-check.checked_time' | translate"
            [placeholder]="'button.select' | translate"
            [showTime]="true"
            [timeOnly]="true">
          </cap-calendar>
        </div>
      </div>
      <div class="row pb-2">
        <div class="col package-types d-flex">
          @for (stock of inventoryChecks; track stock) {
            <div class="type pr-1">
              {{ stock.fust_package_type ? stock.fust_package_type : stock.fustPackageType.value }}
            </div>
          }
        </div>
      </div>
      <div class="row pb-2">
        <div class="col-2">
          <span class="type">{{ 'dxp.fust.inventory-check.list.calculated-stock' | translate }}</span>
        </div>
        <div class="col inventory-check-section calculated-stock d-flex">
          @for (stock of inventoryChecks; track stock) {
            <div class="pr-1">
              {{ stock.calculated }}
            </div>
          }
        </div>
      </div>
      <div class="row pb-2">
        <div class="col-2">
          <span class="type">{{ 'dxp.fust.inventory-check.list.counted-stock' | translate }}</span>
        </div>
        <div class="col inventory-check-section d-flex">
          @for (stock of inventoryChecks; track stock) {
            <cap-input
              class="pr-1"
              filter="pnum"
              [formControlName]="
                (stock.fust_package_type ? stock.fust_package_type : stock.fustPackageType.value) + '-amount'
              ">
            </cap-input>
          }
        </div>
      </div>
      <div class="row pb-2">
        <div class="col-2">
          <span class="type">{{ 'dxp.fust.inventory-check.list.difference' | translate }}</span>
        </div>
        <div class="col inventory-check-section d-flex">
          @for (stock of inventoryChecks; track stock) {
            <div class="difference pr-1">
              @if (stock.calculated !== stock?.amount) {
                <span class="yes">{{ 'general.yes' | translate }}</span>
              }
              @if (stock.calculated === stock?.amount) {
                <span class="no">{{ 'general.no' | translate }}</span>
              }
            </div>
          }
        </div>
      </div>
      <div class="row">
        <div class="col-2">
          <span class="type">{{ 'dxp.fust.inventory-check.list.comment' | translate }}</span>
        </div>
        <div class="col p-0">
          <cap-textarea formControlName="comment" [attr.data-test]="'comment'"></cap-textarea>
        </div>
      </div>
    </form>
  </div>
} @else {
  @for (item of [1, 2, 3, 4, 5]; track item) {
    <div class="row px-4">
      <div class="col-3">
        <cap-skeleton height="40px"></cap-skeleton>
      </div>
      <div class="col">
        <cap-skeleton height="40px"></cap-skeleton>
      </div>
    </div>
  }
}

<div class="popup-actions">
  <cap-button styleClass="secondary mt-3" [label]="'button.cancel' | translate" (onClick)="onClose()"></cap-button>

  <cap-button
    appPreventDoubleClick
    styleClass="primary ml-2 mt-3"
    [label]="'dxp.button.save-changes' | translate"
    (clickEvent)="onSave()">
  </cap-button>
</div>
