import { Store } from '@ngxs/store';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FustInventoryCheckService } from '@core/api/fust-inventory-check.service';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CalculatedInventoryCheck, InventoryCheck } from '@core/models/inventory-check.model';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { DestroyBase } from '@core/base/destroy.class';
import { FormUtil } from '@core/utils/form-util';
import { CapturumBuilderActionService } from '@capturum/builders/core';
import { FetchActionsExecution } from '@store/general/general.actions';
import { BuilderActionType } from '@core/enums/builder-action-type.enum';

@Component({
  selector: 'app-inventory-check-form',
  templateUrl: './inventory-check-form.component.html',
  styleUrls: ['./inventory-check-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InventoryCheckFormComponent extends DestroyBase implements OnInit {
  public form: UntypedFormGroup;
  public inventoryChecks: CalculatedInventoryCheck[];

  constructor(
    private readonly formBuilder: FormBuilder,
    private dialogRef: DynamicDialogRef,
    private notificationService: NotificationService,
    private store: Store,
    private translateService: TranslateService,
    private inventoryCheckService: FustInventoryCheckService,
    private config: DynamicDialogConfig,
    private actionService: CapturumBuilderActionService,
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.config?.data?.item?.id) {
      this.getFustInventoryCheckData(this.config.data.item.id);
    } else {
      this.getCalculatedData();
    }

    this.createForm();
  }

  public onClose(refresh = false): void {
    this.dialogRef.close(refresh);
  }

  public onSave(): void {
    if (this.form.invalid) {
      FormUtil.markAsTouched(this.form);

      return;
    }

    const difference = Object.values(this.inventoryChecks).some((val) => {
      return val.calculated !== val.amount;
    });
    const data = {
      id: this.config?.data?.item?.id ? this.config.data.item.id : null,
      checked_at: this.form.controls.checked_at.value,
      checked_time: this.form.controls.checked_time.value,
      comment: this.form.controls.comment.value,
      fustInventoryCheckPackages: this.inventoryChecks,
      difference,
    };

    this.inventoryCheckService.saveInventoryCheck(data).subscribe(() => {
      const message = this.config?.data?.item?.id
        ? this.translateService.instant('dxp.fust.inventory-check.edit-inventory-check.success.message')
        : this.translateService.instant('dxp.fust.inventory-check.add-inventory-check.success.message');

      this.notificationService.success(this.translateService.instant('toast.success.title'), message);

      this.store.dispatch(new FetchActionsExecution(BuilderActionType.closePopup));
      this.onClose(true);
      this.actionService.broadcastActionExecuted(
        {
          options: { refresh: true },
          key: 'edit',
          type: 'dot_menu',
        },
        null,
        null,
      );
    });
  }

  private getCalculatedData(): void {
    this.inventoryCheckService
      .getCalculatedStock()
      .pipe(
        tap((stocks) => {
          return (this.inventoryChecks = stocks);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        if (this.inventoryChecks) {
          this.setStockAmount(this.inventoryChecks);
        }
      });
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      id: [null],
      comment: [null],
      checked_time: [null],
      checked_at: [null],
    });
  }

  private getFustInventoryCheckData(inventoryCheckId: string): void {
    this.inventoryCheckService
      .get(inventoryCheckId, { include: ['fustInventoryCheckPackages.fustPackageType'] })
      .subscribe((data) => {
        this.inventoryChecks = data?.fustInventoryCheckPackages;

        if (this.inventoryChecks) {
          this.setStockAmount(this.inventoryChecks, data);
        }
      });
  }

  private setStockAmount(inventoryChecks: CalculatedInventoryCheck[], data?: InventoryCheck): void {
    inventoryChecks.forEach((inventoryCheck) => {
      const fustPackageType = inventoryCheck.fust_package_type
        ? inventoryCheck.fust_package_type
        : inventoryCheck.fustPackageType?.value;
      const amount = this.formBuilder.control(inventoryCheck.fust_package_type ? null : inventoryCheck.amount, [
        Validators.required,
      ]);

      this.form.addControl(`${fustPackageType}-amount`, amount);

      if (data) {
        this.form.patchValue(data);
      }

      this.form.updateValueAndValidity();
    });

    this.isDifferent();
  }

  private isDifferent(): void {
    this.inventoryChecks.forEach((inventory) => {
      const fustPackageType = inventory.fust_package_type
        ? inventory.fust_package_type
        : inventory.fustPackageType.value;

      this.form.controls[`${fustPackageType}-amount`].valueChanges
        .pipe(debounceTime(500), takeUntil(this.destroy$))
        .subscribe((value) => {
          inventory.amount = +value;
          inventory.difference = inventory.amount !== inventory.calculated;
        });
    });
  }
}
