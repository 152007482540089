import { Component } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';
import { DialogService } from 'primeng/dynamicdialog';
import { CommentPopUpComponent } from '../comment-pop-up/comment-pop-up.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent extends ListRendererWidgetBase {
  constructor(
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
  ) {
    super();
  }

  public openCommentInPopUp(comment: string): void {
    this.dialogService.open(CommentPopUpComponent, {
      header: this.translateService.instant('builders.edit_input.comment'),
      data: {
        comment,
      },
    });
  }
}
