import { Component } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-comment-pop-up',
  templateUrl: './comment-pop-up.component.html',
  styleUrls: ['./comment-pop-up.component.scss'],
})
export class CommentPopUpComponent {
  public comment: string;

  constructor(private config: DynamicDialogConfig) {
    this.comment = this.config?.data?.comment;
  }
}
