import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService, ApiSingleResult, ListOptions } from '@capturum/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CalculatedInventoryCheck, InventoryCheck } from '@core/models/inventory-check.model';

@Injectable({
  providedIn: 'root',
})
export class FustInventoryCheckService extends ApiService<InventoryCheck> {
  protected endpoint = 'fust-inventory-check';

  constructor(public apiHttp: ApiHttpService) {
    super(apiHttp);
  }

  public getCalculatedStock(options?: ListOptions): Observable<CalculatedInventoryCheck[]> {
    let url = `/${this.endpoint}/calculated`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get<ApiSingleResult<CalculatedInventoryCheck[]>>(url).pipe(
      map((response) => {
        return response?.data;
      }),
    );
  }

  public saveInventoryCheck(data: InventoryCheck, options?: ListOptions): Observable<InventoryCheck> {
    let url = `/${this.endpoint}/save`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.post(url, data);
  }
}
