import { Store } from '@ngxs/store';
import { BuildersFormKey } from '@core/enums/builders-key.enum';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FetchActionsExecution } from '@store/general/general.actions';
import { first } from 'rxjs/operators';
import { BuilderActionType } from '@core/enums/builder-action-type.enum';
import { FormSaverService } from '@capturum/builders/form-renderer';
import { CapturumBuilderActionService } from '@capturum/builders/core';

@Component({
  selector: 'app-confirm-reception',
  templateUrl: './confirm-reception.component.html',
  styleUrls: ['./confirm-reception.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmReceptionComponent {
  public buildersKey: BuildersFormKey = BuildersFormKey.confirmReceptionStock;

  constructor(
    private dialogRef: DynamicDialogRef,
    private notificationService: NotificationService,
    private store: Store,
    private translateService: TranslateService,
    private formSaverService: FormSaverService,
    private actionService: CapturumBuilderActionService,
    public config: DynamicDialogConfig,
  ) {}

  public onClose(): void {
    this.dialogRef.close();
  }

  public onSave(): void {
    this.formSaverService
      .submit(this.buildersKey)
      .pipe(first())
      .subscribe(() => {
        this.store.dispatch(new FetchActionsExecution(BuilderActionType.closePopup));
        this.notificationService.success(
          this.translateService.instant('toast.success.title'),
          this.translateService.instant('dxp.fust.stock-deliveries.confirm-reception.success.message'),
        );

        this.onClose();
        this.actionService.broadcastActionExecuted(
          {
            options: { refresh: true },
            key: null,
            type: null,
          },
          null,
          null,
        );
      });
  }
}
