<div class="popup-content">
  <cpb-form-renderer
    [context]="{ id: config?.data?.item?.id }"
    [formKey]="buildersKey"
    [modelId]="config?.data?.item?.id"
    [showHeader]="false">
  </cpb-form-renderer>
</div>

<div class="popup-actions">
  <cap-button styleClass="secondary mt-3" [label]="'button.cancel' | translate" (onClick)="onClose()"> </cap-button>

  <cap-button
    appPreventDoubleClick
    styleClass="primary ml-2 mt-3"
    [label]="'dxp.button.save-changes' | translate"
    (clickEvent)="onSave()">
  </cap-button>
</div>
